:root {
    font: 12px/14px sans-serif;
    --main-bg-color : white;
    --alt-bg-color : lightyellow;
    --header-bg-color : lightgreen;
    --secondary-font-size : 80%;
    --primary-font-size : 120%;
    --top-border : 30px;
    --inactive-bg : repeating-linear-gradient(
            -45deg,
            yellow,
            yellow 20px,
            black 20px,
            black 21px
    );

}

.scrollable {
    height: 200px;
    overflow: scroll;
}

.scrollable > h3 {
    position: sticky;
    top: 0px;
    background-color: var(--main-bg-color);
}

.unpub {
    background: var(--inactive-bg)
}