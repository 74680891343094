@import "defs.css";

.feature {
    background-color: linen;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    display: grid;
    grid-column-gap: 5px;
    grid-template:
        "title title title title" minmax(20px, auto)
        "inm param param param" minmax(20px, auto)
        "inm mkt mkt mkt" minmax(20px, auto)
        ".   mkt mkt mkt" minmax(20px, auto)
        ".   mkt mkt mkt" minmax(20px, auto)
        / 0.5fr 1fr 1fr 1fr;
}

.feature > .title {
    text-align: left;
    font-weight: bolder;
    grid-area : title;
    position:sticky;
    z-index: 8;
    background-color: var(--alt-bg-color);
    top:var(--top-border) ;
}


.feature > .param {
    grid-area : param;
}

.feature > .fctl {
    background-color: #F8F8F8;
    padding: 5px;
    border-radius: 5px;
    z-index: 5;
    border-width: 2px;
    border-style: solid;
    border-color: black;
}

.feature > .summary {
    grid-area : summary
}

.feature > .market-list {
    grid-area : mkt
}

.follow {
    position:sticky;
    top:calc(var(--top-border) + 20px) ;
}

.feature > .incoming {
    grid-area : inm;
}

.in-score-params {


}