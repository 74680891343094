@import "defs.css";


.selections {
    max-height: 200px;
    overflow: scroll;
    display: grid;
    grid-row-gap: 5px;
    grid-template-columns: [sel-start] 0.5fr 0.5fr 0.5fr 0.5fr 1fr 1fr 1fr [sel-end];
    grid-auto-rows: 20px;
    grid-auto-flow: dense;
}

.header {
    font-weight: bolder;
    position: sticky;
    top: 0px;
    z-index: 1;
    background: var(--header-bg-color);
}
.selections{
    text-align: center;

}
.selections > div {

}

.invisible > * {
    background-color: var(--main-bg-color);
}
.alt > * {
    background-color: var(--alt-bg-color);
}

.invisible {
    /*grid-column-start: 1;
    grid-column-end: 7;*/
    display: contents;
    background-color: var(--main-bg-color);
}
.selections input,
.ctl input,
.ctl span ,
.in-selections input,
.in-score-params input{
    font-size: var(--secondary-font-size);
    width: 7em;
}



.odd {
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--primary-font-size)
}

.unpub > .odd {
    background: var(--inactive-bg)
}

.raw {
    font-size: var(--secondary-font-size);
}

.market {
    display: grid;
    grid-template:
        " title  .  mrg max" minmax(20px, auto)
        "publish prp lin log" minmax(20px, auto)
        / 2fr 1fr 1fr 1fr;
    width: 50%;
}

.incoming-margin{
    font-size: var(--secondary-font-size);
}
.market-list{

}

.market > .ctl {

}

.market > label {
    display: flex;
    flex-direction: column;
}

.market-list > .mkt {
    grid-area: mkt;
}



.summary > ul{
    position: sticky;
    top: 0px;
}

.market-list > .summary {
    grid-area: summary;
}

.market > .title {
    font-weight: bolder;
    grid-area: title;
}

.market > .mrg {
    grid-area: mrg;
}

.market > .max {
    grid-area: max;
}

.market > .active {
    grid-area: publish;
}

.market > .prp {
    grid-area: prp;
}

.market > .lin {
    grid-area: lin;
}

.market > .log {
    grid-area: log;
}

.alert {
    font-size: var(--secondary-font-size);
}


.in-selections {
    /*height: 200px;*/
    text-align: center;
    overflow: scroll;
    display: grid;
    grid-row-gap: 5px;
    grid-template-columns: [sel-start] 1fr 1fr 3em 1fr [sel-end];
    grid-auto-rows: auto;
}

.in-score-params{
    text-align: center;
    overflow: scroll;
    display: grid;
    grid-row-gap: 5px;
    grid-template-columns: [sel-start] 1fr 1fr 3em 1fr 1fr 1fr 1fr[sel-end];
    grid-auto-rows: auto;
}

.in-market {
    /*display: grid;
    grid-template:
        "title mrg max ." minmax(20px, auto)
        "title prp lin log" minmax(20px, auto)
        / 2fr 1fr 1fr 1fr;
    width: 50%;*/
}