input {
    width: 100%;
    font-size: 200%;
}

.search-form {
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;

}

