@import "defs.css";

.bout {
    display: grid;
    grid-column-gap: 5px;
    grid-template:
        "title title " minmax(var(--top-border), auto)
        "side feature" minmax(20px, auto)
        ".  feature" minmax(20px, auto)
        / 70px 8fr;
    z-index: 11;
}

.bout > .title {
    background-color: var(--main-bg-color);
    grid-area : title;
    top: 0px;
    position: sticky;
    z-index: 15;
}

.bout > .feat-list{
    grid-area : side;
    top: var(--top-border);
    position: sticky;
}



.bout > .feature-exp-list {
    grid-area : feature;
}


